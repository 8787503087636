const ANALYTICS_PAGES = {
  '/': 'Home Page',
  '/landing': 'Landing Page',
  '/bundles': 'Bundle Selection',
  '/energy/selection': 'Energy Selection',
  '/energy/led': 'Daffodil',
  '/energy/summary': 'Energy Details',
  '/homephone': 'Phone',
  '/broadband': 'Broadband',
  '/broadband/newline': 'New Phone Line',
  '/broadband/newline/appointment': 'Phone Appointment',
  '/mobile': 'Mobile Tariff',
  '/mobile/broadband': 'Mobile Broadband',
  '/mobile/options': 'Mobile Options',
  '/mobile/summary': 'Mobile Summary',
  '/cashbackcard': 'CashBack Card Upsell',
  '/order/billprotector': 'Bill Protector',
  '/order/aboutyou': 'About You',
  '/order/confirmation': 'Pre-Order Summary',
  '/checkout/payment': 'Payment',
  '/checkout/thankyou': 'Welcome to the Club',
};

export default ANALYTICS_PAGES;

export const EVENT_TYPE = {
  PAGE_VIEW: 'page_view',
  BEHAVIOUR: 'behaviour',
  APPLICATION: 'application',
};

export const EVENTS = {
  APPLICATION_STARTED: 'jtc_application_started',
  APPLICATION_SERVICES_PRESENTED: 'application_services_presented',
  APPLICATION_SERVICES_SELECTED: 'application_services_selected',
  APPLICATION_SUMMARY_PRESENTED: 'application_summary_presented',
  APPLICATION_SUMMARY_REVIEWED: 'application-summary-reviewed',
  APPLICATION_SUBMITTED: 'application_submitted',
  APPLICATION_COMPLETED: 'application_completed',
  REMOTE_CONTROL_CHANGE: 'remote_control_change',
  PROSPECT_CREDIT_CHECK_COMPLETED: 'prospect-credit-check-completed',
  PROSPECT_CREDIT_CHECK_DECLINED: 'prospect-credit-check-declined',

  PAYMENT_REQUESTED: 'payment_requested',
  PAYMENT_SUBMITTED: 'payment-submitted',
  PAYMENT_ERRORED: 'payment_errored',
  PAYMENT_REJECTED: 'payment_rejected',
  PAYMENT_COMPLETED: 'payment-completed',

  BROADBAND_PACKAGES_PRESENTED: 'jtc_broadband_packages_presented',
  BROADBAND_TARIFFS_DISPLAYED: 'jtc_broadband_tariffs_displayed',
  BROADBAND_TARIFF_SELECTED: 'jtc_broadband_tariff_selected',
  BROADBAND_TABS_SELECT: 'broadband-tabs-select',
  BROADBAND_EERO_NUMBER_SELECT: 'broadband-eero-number-select',
  BROADBAND_ROUTER_SELECTED: 'jtc_broadband_router_selected',
  BROADBAND_QUOTE_ACCEPTED: 'jtc_broadband_quote_accepted',

  MOBILE_EDIT_BUDGET_CONTROL_CLICKED: 'jtc_mobile_edit_budget_control_clicked',
  MOBILE_EDIT_NUMBER_CLICKED: 'jtc_mobile_edit_number_clicked',
  MOBILE_QUOTE_ACCEPTED: 'jtc_mobile_quote_accepted',

  APPLICATION_SAVE_AND_SHARE_DIALOG_OPENED:
    'application-save-and-share-dialog-opened',
  APPLICATION_SAVED: 'application-saved',
  APPLICATION_SHARED: 'application-shared',
  APPLICATION_RESTORE_SUCCESSFUL: 'application-restore-successful',
  APPLICATION_RESTORE_FAILED: 'application-restore-failed',

  EVERYBODY_SAVES_DROPDOWN_PRESENTED: 'everybody-saves-dropdown-presented',
  EVERYBODY_SAVES_DROPDOWN_OPENED: 'everybody-saves-dropdown-opened',
  EVERYBODY_SAVES_BANNER_LINK_CLICKED: 'everybody-saves-banner-link-clicked',

  HOME_AUDIT_EDIT_SUBMIT_CLICKED: 'home-audit-edit-submit-clicked',
  HOME_AUDIT_DATE_INPUT_CHANGED: 'home-audit-date-input-changed',
  BUNDLES_SERVICE_SELECTED: 'bundles_service_selected',
  BUNDLES_SERVICE_REMOVED: 'bundles_service_removed',
  BUNDLES_CBC_LINK_CLICKED: 'bundles_cbc_link_clicked',
  BUNDLE_VIEWPORT_FULLY_DISPLAYED: 'bundle_viewport_fully_displayed',
  MAX_SAVINGS_VIEWPORT_DISPLAYED: 'max_savings_viewport_displayed',

  CBC_ADD_CLICKED: 'cbc_add_clicked',
  CBC_NO_THANKS_CLICKED: 'cbc_no_thanks_clicked',
  CBC_TERMS_CLICKED: 'cbc_terms_clicked',
  CBC_RETAILERS_CAROUSEL_CLICKED: 'cbc_retailers_carousel_clicked',
  CBC_TRUSTPILOT_CAROUSEL_CLICKED: 'cbc_trustpilot_carousel_clicked',
  CBC_ON_THE_GO_CAROUSEL_CLICKED: 'cbc_on_the_go_carousel_clicked',
  CBC_APPLICATION_SUBMITTED: 'cbc_application_submitted',
  CBC_CASHBACK_CHALLENGE_CLICKED: 'cbc_cashback_challenge_clicked',

  INSURANCE_BILL_PROTECTOR_WHATS_COVERED_CLICKED:
    'jtc_bill_protector_cover_dropdown_clicked',
  INSURANCE_INCOME_PROTECTOR_WHATS_COVERED_CLICKED:
    'jtc_income_protector_cover_dropdown_clicked',
  INSURANCE_PROTECTOR_QUOTE_ACCEPTED: 'jtc_protector_policy_quote_accepted',
  INSURANCE_PROTECTOR_NO_THANKS_CLICKED:
    'jtc_protector_policy_no_thanks_clicked',
  INSURANCE_PROTECTOR_POLICIES_DISPLAYED: 'jtc_protector_policies_displayed',
  INSURANCE_PROTECTOR_POLICY_REVIEWED: 'jtc_protector_policy_quote_reviewed',

  INSURANCE_BOILER_COVER_DROPDOWN_CLICKED: 'jtc_boiler_cover_dropdown_clicked',
  INSURANCE_BOILER_COVER_QUOTE_DOCUMENT_OPENED:
    'jtc_boiler_cover_quote_document_opened',

  INSURANCE_BOILER_COVER_POLICY_QUOTE_REVIEWED:
    'jtc_boiler_cover_policy_quote_reviewed',
  INSURANCE_BOILER_COVER_POLICY_QUOTE_ACCEPTED:
    'jtc_boiler_cover_policy_quote_accepted',
  INSURANCE_BOILER_COVER_POLICY_NO_THANKS_CLICKED:
    'jtc_boiler_cover_policy_no_thanks_clicked',

  INSURANCE_EXPRESSED_INTEREST_IN_BOILER_COVER_POLICY:
    'jtc_expressed_interest_in_boiler_cover_policy',
  INSURANCE_SKIPPED_BOILER_COVER_POLICY_FROM_INELIGIBILITY:
    'jtc_skipped_boiler_cover_policy_from_ineligibility',

  JTC_MARKETING_PREFERENCES_DIALOG_OPENED:
    'jtc_marketing_preferences-dialog-opened',
  ADDRESS_SUBMITTED: 'jtc_address_submitted',

  SUMMARY_PRODUCT_REMOVE: 'jtc_summary_service_removed',
  SUMMARY_PRODUCT_ADD: 'jtc_summary_service_added',
  SUMMARY_PRODUCT_DETAIL_CLICK: 'jtc_service_detail_clicked',

  THANKYOU_APP_DOWNLOAD_CLICKED: 'thankyou_app_download_clicked',
  THANKYOU_PARTNER_SIGNUP_CTA_DISPLAYED:
    'thankyou_partner_signup_cta_displayed',
  THANKYOU_PARTNER_SIGNUP_CTA_CLICKED: 'thankyou_partner_signup_cta_clicked',
  THANKYOU_RAF_COPY_LINK_CTA_DISPLAYED: 'thankyou_raf_copy_link_cta_displayed',
  THANKYOU_RAF_COPY_LINK_CTA_CLICKED: 'thankyou_raf_copy_link_cta_clicked',
  THANKYOU_HOME_INSURANCE_RENEWAL_DATE_CHANGED:
    'thankyou_home_insurance_renewal_date_changed',
  THANKYOU_HOME_INSURANCE_RENEWAL_DATE_SUBMITTED:
    'thankyou_home_insurance_renewal_date_submitted',

  OPTLY_EVENT_TRACK: 'optimizely_event_tracked',
  OPTLY_DECIDE_TRACK: 'optimizely_decision_called',
  ENERGY_QUOTE_REQUESTED: 'jtc_energy_quote_requested',
  ENERGY_SUMMARY_INFO_OPENED: 'jtc_energy_summary_info_opened',
  ENERGY_SUMMARY_TIL_OPENED: 'jtc_energy_summary_til_opened',
  ENERGY_SUMMARY_QUOTE_ACCEPTED: 'jtc_energy_quote_accepted',
  ENERGY_SUMMARY_TARIFF_SELECTED: 'jtc_energy_tariff_selected',
  ENERGY_EV_TARIFF_INFO_DISPLAYED: 'jtc_energy_ev_tariff_info_displayed',
  ENERGY_EV_TARIFF_FULL_DETAILS_CLICKED:
    'jtc_energy_ev_tariff_full_details_clicked',
  ENERGY_EV_TARIFF_MARKETING_INTEREST_EXPRESSED:
    'jtc_energy_ev_tariff_marketing_interest_expressed',

  CREDIT_CHECK_DISPLAYED: 'jtc_credit_check_displayed',
  REFERRER_SUBMITTED: 'jtc_referrer_submitted',
  BASKET_PANEL_DISPLAYED: 'jtc_basket_panel_displayed',
  BASKET_PANEL_EXPANDED: 'jtc_basket_panel_expanded',

  RAF_LEAD_CAPTURE_CTA_DISPLAYED: 'jtc_raf_lead_capture_cta_displayed',
  RAF_LEAD_CAPTURE_CTA_CLICKED: 'jtc_raf_lead_capture_cta_clicked',
  RAF_LEAD_CAPTURE_FORM_SUBMITTED: 'jtc_raf_lead_capture_form_submitted',

  OTS_BROADBAND_NOT_FOUND_INFO_DISPLAYED:
    'jtc_ots_broadband_not_found_info_displayed',
  OTS_FIRST_TRY_SUCCESS_MODAL_DISPLAYED:
    'jtc_ots_first_try_success_modal_displayed',
  OTS_SECOND_TRY_SUCCESS_MODAL_DISPLAYED:
    'jtc_ots_second_try_success_modal_displayed',
};

export const VERSIONS = {
  [EVENT_TYPE.PAGE_VIEW]: '1-0-0',
  [EVENTS.APPLICATION_STARTED]: '1-0-0',
  [EVENTS.APPLICATION_SERVICES_PRESENTED]: '1-0-3',
  [EVENTS.APPLICATION_SERVICES_SELECTED]: '1-0-0',
  [EVENTS.APPLICATION_SUMMARY_PRESENTED]: '1-0-3',
  [EVENTS.APPLICATION_SUMMARY_REVIEWED]: '1-0-0',
  [EVENTS.APPLICATION_SUBMITTED]: '1-0-6',
  [EVENTS.APPLICATION_COMPLETED]: '1-0-3',
  [EVENTS.REMOTE_CONTROL_CHANGE]: '1-0-0',
  [EVENTS.PROSPECT_CREDIT_CHECK_COMPLETED]: '1-0-0',
  [EVENTS.PROSPECT_CREDIT_CHECK_DECLINED]: '1-0-0',
  [EVENTS.PAYMENT_REQUESTED]: '1-0-1',
  [EVENTS.PAYMENT_SUBMITTED]: '1-0-0',
  [EVENTS.PAYMENT_ERRORED]: '1-0-1',
  [EVENTS.PAYMENT_REJECTED]: '1-0-1',
  [EVENTS.PAYMENT_COMPLETED]: '1-0-0',
  [EVENTS.BROADBAND_PACKAGES_PRESENTED]: '1-0-0',
  [EVENTS.BROADBAND_TABS_SELECT]: '1-0-0',
  [EVENTS.BROADBAND_EERO_NUMBER_SELECT]: '1-0-0',
  [EVENTS.BROADBAND_TARIFFS_DISPLAYED]: '1-0-0',
  [EVENTS.BROADBAND_TARIFF_SELECTED]: '1-0-0',
  [EVENTS.BROADBAND_ROUTER_SELECTED]: '1-0-0',
  [EVENTS.BROADBAND_QUOTE_ACCEPTED]: '1-0-0',
  [EVENTS.MOBILE_EDIT_BUDGET_CONTROL_CLICKED]: '1-0-0',
  [EVENTS.MOBILE_EDIT_NUMBER_CLICKED]: '1-0-0',
  [EVENTS.MOBILE_QUOTE_ACCEPTED]: '1-0-0',
  [EVENTS.APPLICATION_SAVE_AND_SHARE_DIALOG_OPENED]: '1-0-0',
  [EVENTS.APPLICATION_SAVED]: '1-0-0',
  [EVENTS.APPLICATION_SHARED]: '1-0-0',
  [EVENTS.APPLICATION_RESTORE_SUCCESSFUL]: '1-0-0',
  [EVENTS.APPLICATION_RESTORE_FAILED]: '1-0-0',
  [EVENTS.EVERYBODY_SAVES_DROPDOWN_PRESENTED]: '1-0-0',
  [EVENTS.EVERYBODY_SAVES_DROPDOWN_OPENED]: '1-0-0',
  [EVENTS.EVERYBODY_SAVES_BANNER_LINK_CLICKED]: '1-0-0',
  [EVENTS.HOME_AUDIT_EDIT_SUBMIT_CLICKED]: '1-0-0',
  [EVENTS.HOME_AUDIT_DATE_INPUT_CHANGED]: '1-0-0',
  [EVENTS.BUNDLES_SERVICE_SELECTED]: '1-0-0',
  [EVENTS.BUNDLES_SERVICE_REMOVED]: '1-0-0',
  [EVENTS.BUNDLES_CBC_LINK_CLICKED]: '1-0-0',
  [EVENTS.BUNDLE_VIEWPORT_FULLY_DISPLAYED]: '1-0-0',
  [EVENTS.MAX_SAVINGS_VIEWPORT_DISPLAYED]: '1-0-0',
  [EVENTS.CBC_ADD_CLICKED]: '1-0-0',
  [EVENTS.CBC_NO_THANKS_CLICKED]: '1-0-0',
  [EVENTS.CBC_TERMS_CLICKED]: '1-0-0',
  [EVENTS.CBC_RETAILERS_CAROUSEL_CLICKED]: '1-0-0',
  [EVENTS.CBC_TRUSTPILOT_CAROUSEL_CLICKED]: '1-0-0',
  [EVENTS.CBC_ON_THE_GO_CAROUSEL_CLICKED]: '1-0-0',
  [EVENTS.CBC_APPLICATION_SUBMITTED]: '1-0-0',
  [EVENTS.CBC_CASHBACK_CHALLENGE_CLICKED]: '1-0-0',
  [EVENTS.INSURANCE_BILL_PROTECTOR_WHATS_COVERED_CLICKED]: '1-0-0',
  [EVENTS.INSURANCE_INCOME_PROTECTOR_WHATS_COVERED_CLICKED]: '1-0-0',
  [EVENTS.INSURANCE_PROTECTOR_QUOTE_ACCEPTED]: '1-0-0',
  [EVENTS.INSURANCE_PROTECTOR_NO_THANKS_CLICKED]: '1-0-0',
  [EVENTS.INSURANCE_PROTECTOR_POLICIES_DISPLAYED]: '1-0-0',
  [EVENTS.INSURANCE_PROTECTOR_POLICY_REVIEWED]: '1-0-0',
  [EVENTS.INSURANCE_BOILER_COVER_DROPDOWN_CLICKED]: '1-0-0',
  [EVENTS.INSURANCE_BOILER_COVER_POLICY_QUOTE_REVIEWED]: '1-0-0',
  [EVENTS.INSURANCE_BOILER_COVER_POLICY_QUOTE_ACCEPTED]: '1-0-0',
  [EVENTS.INSURANCE_EXPRESSED_INTEREST_IN_BOILER_COVER_POLICY]: '1-0-0',
  [EVENTS.INSURANCE_SKIPPED_BOILER_COVER_POLICY_FROM_INELIGIBILITY]: '1-0-0',
  [EVENTS.INSURANCE_BOILER_COVER_POLICY_NO_THANKS_CLICKED]: '1-0-0',
  [EVENTS.INSURANCE_BOILER_COVER_QUOTE_DOCUMENT_OPENED]: '1-0-1',
  [EVENTS.JTC_MARKETING_PREFERENCES_DIALOG_OPENED]: '1-0-0',
  [EVENTS.SUMMARY_PRODUCT_REMOVE]: '1-0-0',
  [EVENTS.SUMMARY_PRODUCT_ADD]: '1-0-0',
  [EVENTS.SUMMARY_PRODUCT_DETAIL_CLICK]: '1-0-0',
  [EVENTS.ADDRESS_SUBMITTED]: '1-0-0',
  [EVENTS.THANKYOU_APP_DOWNLOAD_CLICKED]: '1-0-0',
  [EVENTS.THANKYOU_PARTNER_SIGNUP_CTA_CLICKED]: '1-0-0',
  [EVENTS.THANKYOU_PARTNER_SIGNUP_CTA_DISPLAYED]: '1-0-0',
  [EVENTS.THANKYOU_RAF_COPY_LINK_CTA_DISPLAYED]: '1-0-0',
  [EVENTS.THANKYOU_RAF_COPY_LINK_CTA_CLICKED]: '1-0-0',
  [EVENTS.THANKYOU_HOME_INSURANCE_RENEWAL_DATE_CHANGED]: '1-0-0',
  [EVENTS.THANKYOU_HOME_INSURANCE_RENEWAL_DATE_SUBMITTED]: '1-0-0',
  [EVENTS.OPTLY_EVENT_TRACK]: '1-0-0',
  [EVENTS.OPTLY_DECIDE_TRACK]: '1-0-0',
  [EVENTS.ENERGY_QUOTE_REQUESTED]: '1-0-2',
  [EVENTS.ENERGY_SUMMARY_INFO_OPENED]: '1-0-0',
  [EVENTS.ENERGY_SUMMARY_TIL_OPENED]: '1-0-0',
  [EVENTS.ENERGY_SUMMARY_QUOTE_ACCEPTED]: '1-0-3',
  [EVENTS.ENERGY_SUMMARY_TARIFF_SELECTED]: '1-0-0',
  [EVENTS.ENERGY_EV_TARIFF_INFO_DISPLAYED]: '1-0-0',
  [EVENTS.ENERGY_EV_TARIFF_FULL_DETAILS_CLICKED]: '1-0-0',
  [EVENTS.ENERGY_EV_TARIFF_MARKETING_INTEREST_EXPRESSED]: '1-0-0',
  [EVENTS.CREDIT_CHECK_DISPLAYED]: '1-0-0',
  [EVENTS.REFERRER_SUBMITTED]: '1-0-0',
  [EVENTS.BASKET_PANEL_EXPANDED]: '1-0-0',
  [EVENTS.BASKET_PANEL_DISPLAYED]: '1-0-0',

  [EVENTS.RAF_LEAD_CAPTURE_CTA_DISPLAYED]: '1-0-0',
  [EVENTS.RAF_LEAD_CAPTURE_CTA_CLICKED]: '1-0-0',
  [EVENTS.RAF_LEAD_CAPTURE_FORM_SUBMITTED]: '1-0-0',
  [EVENTS.OTS_BROADBAND_NOT_FOUND_INFO_DISPLAYED]: '1-0-0',
  [EVENTS.OTS_FIRST_TRY_SUCCESS_MODAL_DISPLAYED]: '1-0-0',
  [EVENTS.OTS_SECOND_TRY_SUCCESS_MODAL_DISPLAYED]: '1-0-0',
};

export const META_CONTEXT_VERSION = '1-0-3';
export const META_CONTEXT_ENTITY = 'jtc_application';

export const OPTLY_DOMAIN = 'uk.co.uw.external.optimizely';
