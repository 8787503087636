export const features = {
  STICKY_NAV: 'enable_sticky_nav',
  ORGANIC_PAGE_NEW_VARIANT: 'organic_page_new_variant',
  SKIP_DD_VERIFICATION: 'skip_dd_verification',
  HIDE_ENERGY_PAYMENT: 'hide_energy_payment_options',
  HIDE_BHC_BOILER_SERVICE: 'hide_bhc_boiler_service',
  ENABLE_SMART_METER_BANNER: 'enable_smart_meter_banner',
  ENABLE_LEAD_CAPTURE: 'enable_lead_capture',
  HIDE_BB_OUT_OFF_CONTRACT: 'hide_bb_out_off_contract',
  SHOW_INSURANCE_ONLY_WHEN_SELECTED: 'show_insurance_only_when_selected',
};

export const variations = {
  [features.STICKY_NAV]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.ORGANIC_PAGE_NEW_VARIANT]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.SKIP_DD_VERIFICATION]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.HIDE_ENERGY_PAYMENT]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.HIDE_BHC_BOILER_SERVICE]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.ENABLE_SMART_METER_BANNER]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.ENABLE_LEAD_CAPTURE]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.HIDE_BB_OUT_OFF_CONTRACT]: {
    ON: 'on',
    OFF: 'off',
  },
  [features.SHOW_INSURANCE_ONLY_WHEN_SELECTED]: {
    ON: 'on',
    OFF: 'off',
  },
};

export const events = {
  APPLICATION_START: 'application_start',
  APPLICATION_COMPLETED: 'application_completed',
  ENGAGED_APPLICATION_START: 'engaged_application_start',
  ENGAGED_APPLICATION_COMPLETED: 'engaged_application_completed',
  ADDRESS_SUBMITTED: 'address_submitted',
  ENERGY_SUBMITTED: 'energy_submitted',
  ADD_BHC_TO_BUNDLE: 'add_bhc_to_bundle',
  LEAD_CAPTURED: 'lead_captured',
  APPLICATION_COMPLETED_WITH_BROADBAND_SELECTED:
    'application_completed_with_broadband_selected',
  APPLICATION_COMPLETED_WITH_INSURANCE_SELECTED:
    'application_completed_with_insurance_selected',
  BOILER_HOME_COVER_SUBMITTED: 'boiler_home_cover_submitted',
  INCOME_PROTECTOR_SUBMITTED: 'income_protector_submitted',
  BILL_PROTECTOR_SUBMITTED: 'bill_protector_submitted',
};
