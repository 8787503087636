import { EVENTS } from 'app/lib/analytics/constants';
import { PartnerLoginApi } from 'redux/modules/PartnerLogin/api';
import { OrderSummaryApi } from 'app/redux/modules/OrderSummary/api';

import {
  getAnalyticsBundlePrices,
  getAnalyticsBundleSelectedProducts,
  getAnalyticsBundlesProductsQuantities,
} from 'redux/modules/Bundles/api';
import { ApplicationApi } from 'redux/modules/App/api';
import {
  getAboutYouFormFields,
  getReferralAndLegalFormFields,
  getReferrerType,
  getOneTouchSwitchFormFields,
} from 'redux/modules/YourDetails/api';

import {
  referralAndLegalSection,
  aboutYouSection,
  oneTouchSwitchSection,
} from 'app/redux/modules/YourDetails/form';

const { REFERRER_ACCOUNT } = referralAndLegalSection;

const {
  ABOUT_YOU_ADDITIONAL_HOLDER_SELECT,
  ABOUT_YOU_CBC_ADDITIONAL_SELECT,
  ABOUT_YOU_RECENT_MOVE,
} = aboutYouSection;

const { HAS_EXISTING_LINE, PROVIDER_NOT_LISTED } = oneTouchSwitchSection;

export default {
  [EVENTS.APPLICATION_STARTED]: (state) => {
    const { lead_code, lead_id } = ApplicationApi.getPageParams(state);
    return {
      partner_prospect_relationship:
        PartnerLoginApi.getPartnerRelationship(state) ?? undefined,
      lead_code: lead_code || undefined,
      lead_id: lead_id || undefined,
    };
  },
  [EVENTS.APPLICATION_SUMMARY_PRESENTED]: (state) => {
    const { monthly: estimatedMonthlyCost, oneOff } = OrderSummaryApi.getTotals(
      state
    );
    const selectedProducts = OrderSummaryApi.getProductIds(state);
    const summaryCoreServices = OrderSummaryApi.getAnalyticsSummaryCoreServices(
      state
    );

    if (Object.keys(selectedProducts).length === 0 || !summaryCoreServices) {
      return {};
    }

    return {
      estimated_monthly_cost: estimatedMonthlyCost,
      one_off_fee: oneOff,
      ...summaryCoreServices,
    };
  },
  [EVENTS.APPLICATION_SUBMITTED]: (state) => {
    const { deposit } = OrderSummaryApi.getTotals(state);
    const productTotals = OrderSummaryApi.getAggregatedTotals(state);
    const coreServiceContext = OrderSummaryApi.getAnalyticsSummaryCoreServices(
      state
    );
    const aboutYouFormfields = getAboutYouFormFields(state);
    const referralAndLegalFormFields = getReferralAndLegalFormFields(state);
    const referrerType = getReferrerType(state);

    const productTotalSum = Object.values(productTotals).reduce((acc, val) => {
      const price = parseInt(val, 10);
      acc += price;
      return acc;
    }, 0);

    const otsFormFields = getOneTouchSwitchFormFields(state);

    return {
      deposit: deposit || undefined,
      credit_check_required: OrderSummaryApi.getCreditCheckRequired(state),
      actual_monthly_cost: productTotalSum,
      core_service_count: coreServiceContext.number_of_core_services,
      joint_account_holder_added:
        aboutYouFormfields[ABOUT_YOU_ADDITIONAL_HOLDER_SELECT],
      cbc_holder_added: aboutYouFormfields[ABOUT_YOU_CBC_ADDITIONAL_SELECT],
      recent_move: aboutYouFormfields[ABOUT_YOU_RECENT_MOVE],
      referrer_type: referrerType ?? undefined,
      referrer_id: referralAndLegalFormFields[REFERRER_ACCOUNT] || undefined,
      existing_broadband_line: otsFormFields[HAS_EXISTING_LINE],
      existing_broadband_provider_not_listed:
        otsFormFields[PROVIDER_NOT_LISTED] || undefined,
      ...coreServiceContext,
    };
  },
  [EVENTS.APPLICATION_SERVICES_SELECTED]: (state) => {
    const selectedProducts = getAnalyticsBundleSelectedProducts(state);
    const bundlePrice = getAnalyticsBundlePrices(state);
    const productsQuantities = getAnalyticsBundlesProductsQuantities(state);

    return {
      ...bundlePrice,
      ...selectedProducts,
      ...productsQuantities,
    };
  },
  [EVENTS.SUMMARY_PRODUCT_REMOVE]: (state) =>
    OrderSummaryApi.getAnalyticsSummaryCoreServices(state),
  [EVENTS.SUMMARY_PRODUCT_ADD]: (state) =>
    OrderSummaryApi.getAnalyticsSummaryCoreServices(state),
  [EVENTS.APPLICATION_COMPLETED]: (state) =>
    OrderSummaryApi.getAnalyticsSummaryCoreServices(state),
};
